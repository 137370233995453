// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Schedules-module__SU3YOIZNGwjo6D6caZYp {\n  position: relative;\n  margin: 20px 0;\n}\n\n.Schedules-module__wRZ7YKJYxUGOk7Q5yKvR {\n  margin-bottom: var(--title-marginBottom);\n}\n\n.Schedules-module__NVJTrOWGZI7bI1pn3hsV .Schedules-module__NZutD9pVFmYCmUHpDHm9 {\n  padding-right: 10px;\n}\n\n.Schedules-module__mMsM7sV41nigCwq1zXFD {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  row-gap: 4px;\n  column-gap: 8px;\n  width: 100%;\n}\n\n.Schedules-module__zu8XfT6KwpsEWimS0orf {\n  display: flex;\n  justify-content: flex-end;\n  flex-grow: 1;\n  gap: 8px;\n  padding-top: 19px;\n}\n\n.Schedules-module__dd_4VlHcofs2mqEe9gDn {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 4px;\n}\n", "",{"version":3,"sources":["webpack://./pages/schedules/Schedules.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,QAAQ;EACR,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,QAAQ;AACV","sourcesContent":[".schedule {\n  position: relative;\n  margin: 20px 0;\n}\n\n.title {\n  margin-bottom: var(--title-marginBottom);\n}\n\n.root .buttons {\n  padding-right: 10px;\n}\n\n.schedules__filters-container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  row-gap: 4px;\n  column-gap: 8px;\n  width: 100%;\n}\n\n.schedules__actions {\n  display: flex;\n  justify-content: flex-end;\n  flex-grow: 1;\n  gap: 8px;\n  padding-top: 19px;\n}\n\n.schedules__user-on-call {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schedule": "Schedules-module__SU3YOIZNGwjo6D6caZYp",
	"title": "Schedules-module__wRZ7YKJYxUGOk7Q5yKvR",
	"root": "Schedules-module__NVJTrOWGZI7bI1pn3hsV",
	"buttons": "Schedules-module__NZutD9pVFmYCmUHpDHm9",
	"schedules__filters-container": "Schedules-module__mMsM7sV41nigCwq1zXFD",
	"schedules__actions": "Schedules-module__zu8XfT6KwpsEWimS0orf",
	"schedules__user-on-call": "Schedules-module__dd_4VlHcofs2mqEe9gDn"
};
export default ___CSS_LOADER_EXPORT___;
