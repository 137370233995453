// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutgoingWebhookForm-module__ooibJNolYY9E9ctVwBzr {\n  display: block;\n}\n\n.OutgoingWebhookForm-module__X5GzsxTNVuczp5WKeYH2 {\n  margin: 16px 0 0 16px;\n}\n\n.OutgoingWebhookForm-module__FTedBozUSBqqEV_lAAWL {\n  margin: 4px;\n}\n\n.OutgoingWebhookForm-module__sAK3S5_UUUMRWgtRWBoU {\n  padding-top: 16px;\n}\n\n.OutgoingWebhookForm-module__hAKBj5WOyXPpxrgo8k4C {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 4px;\n}\n\n.OutgoingWebhookForm-module__MrzxitlWbL6mO_bCFlGK {\n  flex-grow: 1;\n}\n\n/* TODO: figure out why this is not picked */\n.OutgoingWebhookForm-module__KJWAoKDANsEQTkNoypBW .OutgoingWebhookForm-module__To6XBtKu7QAWMTOKbotS.OutgoingWebhookForm-module__eykohTe_tPsEcz30o23o {\n  display: none !important;\n}\n", "",{"version":3,"sources":["webpack://./containers/OutgoingWebhookForm/OutgoingWebhookForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,QAAQ;AACV;;AAEA;EACE,YAAY;AACd;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB;AAC1B","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 16px 0 0 16px;\n}\n\n.content {\n  margin: 4px;\n}\n\n.tabs__content {\n  padding-top: 16px;\n}\n\n.form-row {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 4px;\n}\n\n.form-field {\n  flex-grow: 1;\n}\n\n/* TODO: figure out why this is not picked */\n.webhooks__drawerContent .cursor.monaco-mouse-cursor-text {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OutgoingWebhookForm-module__ooibJNolYY9E9ctVwBzr",
	"title": "OutgoingWebhookForm-module__X5GzsxTNVuczp5WKeYH2",
	"content": "OutgoingWebhookForm-module__FTedBozUSBqqEV_lAAWL",
	"tabs__content": "OutgoingWebhookForm-module__sAK3S5_UUUMRWgtRWBoU",
	"form-row": "OutgoingWebhookForm-module__hAKBj5WOyXPpxrgo8k4C",
	"form-field": "OutgoingWebhookForm-module__MrzxitlWbL6mO_bCFlGK",
	"webhooks__drawerContent": "OutgoingWebhookForm-module__KJWAoKDANsEQTkNoypBW",
	"cursor": "OutgoingWebhookForm-module__To6XBtKu7QAWMTOKbotS",
	"monaco-mouse-cursor-text": "OutgoingWebhookForm-module__eykohTe_tPsEcz30o23o"
};
export default ___CSS_LOADER_EXPORT___;
