// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Maintenance-module__j1FdDcPLUbavSIRtvaay {\n  width: 400px;\n}\n\n.Maintenance-module__Xbel_TiGiN_sJYVYoodN {\n  display: flex;\n  justify-content: space-between;\n}\n\n.Maintenance-module__kFnQVKNziB4i2Xy85HDJ {\n  margin-bottom: var(--title-marginBottom);\n}\n\n.Maintenance-module__r_Om2UJPusqeUrhFViEC {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./pages/maintenance/Maintenance.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,WAAW;AACb","sourcesContent":[".select {\n  width: 400px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.title {\n  margin-bottom: var(--title-marginBottom);\n}\n\n.info-box {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "Maintenance-module__j1FdDcPLUbavSIRtvaay",
	"header": "Maintenance-module__Xbel_TiGiN_sJYVYoodN",
	"title": "Maintenance-module__kFnQVKNziB4i2Xy85HDJ",
	"info-box": "Maintenance-module__r_Om2UJPusqeUrhFViEC"
};
export default ___CSS_LOADER_EXPORT___;
